import React from 'react';
import I18n from '../../i18n';
import QuestionRow from './QuestionRow';
import DisplayTable from './DisplayTable';
import BaseTable from './BaseTable';

const DisplayQuestionTable = ({
  questions,
  editingQuestionId,
  editingData,
  editingDataMap,
  setEditingDataMap,
  duplicatedRows = [],
  newQuestion,
  newRowRef,
  selectedQuestionId,
  handleQuestionEdit,
  handleQuestionDelete,
  handleQuestionSave,
  handleQuestionDuplicate,
  handleQuestionCancel,
  setEditingData,
  setNewQuestion,
  handleQuestionRowClick,
}) => {
  const headers = [
    { 
      title: I18n.t('admin.headers.actions'),
      className: 'col-actions',
      tooltip: I18n.t('admin.headers.tooltips.actions')
    },
    { 
      title: I18n.t('admin.headers.questionId'),
      className: 'col-id',
      tooltip: I18n.t('admin.headers.tooltips.questionId')
    },
    { 
      title: I18n.t('admin.headers.name'),
      className: 'col-name',
      tooltip: I18n.t('admin.headers.tooltips.name')
    },
    { 
      title: I18n.t('admin.headers.text'),
      className: 'col-text',
      tooltip: I18n.t('admin.headers.tooltips.text')
    },
    { 
      title: I18n.t('admin.headers.difficulty'),
      className: 'col-difficulty',
      tooltip: I18n.t('admin.headers.tooltips.difficulty')
    }
  ];

  const renderRow = (question, isEditing, actions) => {
    if (!question) return null;

    const isDuplicate = question.id?.toString().startsWith('dup_');
    const currentEditData = isDuplicate ? editingDataMap[question.id] : editingData;

    const editData = {
      ...question,
      name: (question === newQuestion ? '' : question.name) || '',
      text: (question === newQuestion ? '' : question.text) || '',
      difficulty: (question === newQuestion ? 1 : question.difficulty) || 1
    };

    const enhancedActions = {
      ...actions,
      onCancel: () => {
        actions.onCancel(question.id);
        if (question === newQuestion) {
          setNewQuestion(null);
        }
      }
    };

    return (
      <QuestionRow
        question={question}
        isEditing={isEditing || question === newQuestion}
        editingData={currentEditData || editData}
        setEditingData={isDuplicate 
          ? (data) => setEditingDataMap(prev => ({...prev, [question.id]: data}))
          : setEditingData
        }
        actions={enhancedActions}
      />
    );
  };

  return (
    <BaseTable
      items={questions.filter(q => q !== newQuestion || editingData)}
      headers={headers}
      editingId={editingQuestionId}
      onEdit={handleQuestionEdit}
      onDelete={handleQuestionDelete}
      onDuplicate={handleQuestionDuplicate}
      onSave={handleQuestionSave}
      onCancel={handleQuestionCancel}
      renderRow={renderRow}
      selectedId={selectedQuestionId}
      onRowClick={handleQuestionRowClick}
      newItem={newQuestion}
      newRowRef={newRowRef}
    />
  );
};

export default DisplayQuestionTable; 