export default {
  basic: {
    loading: 'جارٍ التحميل...',
    selectLanguage: 'اختر اللغة',
  },
  navbar: {
    home: 'الصفحة الرئيسية',
    members: 'منطقة الأعضاء',
    account: 'الحساب',
    practice: 'نظام التدريب',
    performance: 'الأداء',
    signin: 'تسجيل الدخول',
    signup: 'إنشاء حساب',
    signout: 'تسجيل الخروج',
    about: 'معلومات عنا',
    contact: 'اتصل بنا',
    admin: 'المشرف',
  },
  home: {
    introTitle: 'مرحباً بكم في testapp!',
    introSubTitle: 'نظام تدريب فريد يتيح لك التحضير للامتحان مع',
    introSubTitle2: 'نجاح مضمون!',
    intro1: 'مرحباً',
    intro2: 'اسمي إليور شاكيد',
    intro3: 'أدعوكم لممارسة مهاراتكم في البرمجة بطريقة',
    intro4: 'إبداعية ومستقلة',
    intro5: 'نظامنا يوفر لك ملاحظات حول اختبارات متعددة لأسئلة شائعة في الامتحان، مما يتيح لك تقييم جودة الكود الخاص بك مع الالتزام بشروط السؤال (التكرار، وقت التشغيل، وغيرها...). رحلتنا طويلة، ولكننا سنسيرها معاً بهدف واضح - مساعدتك على كتابة الكود بشكل مثالي',
    intro6: 'والنجاح في الامتحان',
    videoError: 'خطأ في تحميل الفيديو',
  },
  account: {
    title: 'صفحة الحساب',
    name: 'الاسم:',
    email: 'البريد الإلكتروني:',
  },
  contact: {
    questions: 'لأي استفسارات:',
    email: 'البريد الإلكتروني:',
  },
  about: {
    founder1: 'إليور: ولد جيد',
    founder2: 'عميت: ولد سيء',
  },
  admin: {
    title: 'صفحة المشرف - صفحة سرية!',
    upload: 'رفع ملف',
    default: 'لم يتم اختيار ملف',
    verify: 'تحقق وارفع ملف CSV',
    alertNoFile: 'لم يتم اختيار ملف',
    alertSuccess: 'تمت معالجة الملف بنجاح',
    alertPleaseUpload: 'يرجى رفع ملف بامتداد .csv',
    questions: "الأسئلة",
    tests: "الاختبارات",
    addQuestion: "إضافة سؤال جديد",
    addTest: "إضافة اختبار جديد",
    confirmDeleteQuestion: "Are you sure you want to delete this question? This will also delete all associated tests.",
    confirmDeleteTest: "Are you sure you want to delete this test?",
    
    // Table headers
    headers: {
      actions: 'الإجراءات',
      id: 'المعرف',
      questionId: 'المعرف',
      testableId: 'المعرف',
      testId: 'المعرف',
      name: 'الاسم',
      testName: 'اسم الاختبار',
      questionName: 'اسم السؤال',
      testableName: 'اسم العنصر القابل للاختبار',
      text: 'النص',
      difficulty: 'الصعوبة',
      type: 'النوع',
      className: 'اسم الفئة',
      initInput: 'الإدخال الأولي',
      timeComplexity: 'تعقيد الوقت',
      recursive: 'تكراري',
      maxLoops: 'الحد الأقصى للحلقات',
      input: 'الإدخال',
      expected: 'النتيجة المتوقعة',
      postprocess: 'المعالجة اللاحقة',
      allowEachExpected: 'أو',
      version: 'الإصدار',
      tooltips: {
        actions: "الإجراءات المتاحة: تعديل، نسخ، حذف",
        id: "معرف تم إنشاؤه تلقائياً",
        questionId: "معرف تم إنشاؤه تلقائياً",
        testableId: "معرف تم إنشاؤه تلقائياً",
        testId: "معرف تم إنشاؤه تلقائياً",
        name: "اسم العنصر",
        testName: "اسم الاختبار",
        questionName: "اسم السؤال",
        testableName: "اسم العنصر القابل للاختبار",
        text: "وصف ومتطلبات السؤال",
        type: "نوع العنصر (دالة/طريقة)",
        className: "اسم الفئة إذا كانت طريقة",
        initInput: "إدخال لتهيئة بيئة الاختبار",
        timeComplexity: "تعقيد الوقت المتوقع",
        recursive: "ما إذا كان التكرار مطلوباً",
        maxLoops: "الحد الأقصى للحلقات المسموح بها",
        input: "قيمة إدخال الاختبار",
        expected: "القيمة المتوقعة",
        allowEachExpected: 'السماح بمطابقة كل قيمة متوقعة بشكل مستقل',
        postprocess: 'كود بايثون لمعالجة المخرجات قبل المقارنة',
        version: "تنسيق الإصدار: رقم، حروف، أو رقم متبوع بحروف"
      }
    },

    // Button labels
    buttons: {
      edit: "تعديل",
      delete: "حذف",
      save: "حفظ",
      cancel: "إلغاء",
      duplicate: "نسخ",
      addQuestion: 'إضافة سؤال',
      addTestable: 'إضافة عنصر',
      addTest: 'إضافة اختبار',
      saving: 'جاري الحفظ...',
      deleting: 'جاري الحذف...',
      loading: 'جاري التحميل...',
      duplicating: 'جاري النسخ...'
    },

    // Messages
    messages: {
      saveSuccess: "تم الحفظ بنجاح",
      saveError: "خطأ في الحفظ",
      deleteSuccess: "تم الحذف بنجاح",
      deleteError: "خطأ في الحذف",
      nameRequired: "الاسم مطلوب",
      duplicateTestName: "يوجد اختبار بهذا الاسم بالفعل لهذا القابل للاختبار",
      duplicateTestableName: "يوجد قابل للاختبار بهذا الاسم بالفعل لهذا السؤال",
      confirmDeleteTest: "هل أنت متأكد من حذف هذا الاختبار؟",
      confirmDeleteTestable: "هل أنت متأكد من حذف هذا القابل للاختبار؟ سيتم حذف جميع الاختبارات المرتبطة به أيضاً."
    },

    testables: "العناصر القابلة للاختبار",
    addTestable: "إضافة عنصر قابل للاختبار",

    headers: {
      actions: 'الإجراءات',
      id: 'المعرف',
      name: 'الاسم',
      testName: 'اسم الاختبار',
      questionName: 'اسم السؤال',
      testableName: 'اسم القابل للاختبار',
      text: 'النص',
      difficulty: 'الصعوبة',
      type: 'النوع',
      className: 'اسم الفئة',
      initInput: 'الإدخال الأولي',
      timeComplexity: 'تعقيد الوقت',
      recursive: 'تكراري',
      maxLoops: 'الحد الأقصى للحلقات',
      input: 'الإدخال',
      expected: 'النتيجة المتوقعة',
      postprocess: 'المعالجة اللاحقة',
      allowEachExpected: 'أو',
      tooltips: {
        actions: "الإجراءات المتاحة: تعديل، نسخ، حذف",
        id: "معرف تم إنشاؤه تلقائياً",
        name: "اسم العنصر",
        testName: "اسم العنصر",
        text: "وصف ومتطلبات السؤال",
        type: "نوع القابل للاختبار (دالة/طريقة)",
        className: "اسم الفئة إذا كانت طريقة",
        initInput: "إدخال لتهيئة بيئة الاختبار",
        timeComplexity: "تعقيد الوقت المتوقع",
        recursive: "ما إذا كان التكرار مطلوباً",
        maxLoops: "الحد الأقصى للحلقات المسموح بها",
        input: "قيمة إدخال الاختبار",
        expected: "القيمة المتوقعة",
        allowEachExpected: 'السماح بمطابقة كل قيمة متوقعة بشكل مستقل',
        postprocess: 'كود بايثون لمعالجة المخرجات قبل المقارنة',
        initInput: 'إدخال لتهيئة بيئة الاختبار',
      }
    }
  },
  practice: {
    title: 'مدقق كود Python',
    darkmode: 'الوضع الليلي',
    lightmode: 'الوضع الفاتح',
    font: 'حجم الخط',
    run: 'تشغيل',
    submit: 'إرسال',
    output: 'الناتج:',
    questionNotFound: 'السؤال {function_name} غير موجود.',
    didYouMean: 'هل تقصد {match}؟'
  },
  results: {
    functionName: 'اسم الوظيفة',
    timeComplexity: 'تعقيد الوقت',
    test: 'الاختبار',
    error: 'خطأ',
    pass: 'ناجح',
    fail: 'فشل',
    noTest: 'لا يوجد اختبار',
    noLimit: 'بدون حد',
    input: 'Input:',
    expected: 'Expected Output:',
    actual: 'Actual Output:',
    failedRecursion: 'Code should be recursive',
    failedLoops: 'Question allowed for {n_loops_allowed} loops, but {actual_loops} found',
    failedStructure: 'Bad structure',
  },
  performance: {
    title: 'جدول التقديمات المحدثة',
  },
  sign: {
    in: 'تسجيل الدخول',
    up: 'إنشاء حساب',
    email: 'البريد الإلكتروني',
    name: 'الاسم',
    back: 'رجوع',
    password: 'كلمة المرور',
    forgotPassword: 'هل نسيت كلمة المرور؟',
    resetPassword: 'إعادة تعيين كلمة المرور',
    codeWill: 'سيتم إرسال رمز إعادة التعيين إلى بريدك الإلكتروني:',
    sendCode: 'إرسال رمز إعادة التعيين',
    defaultCode: 'أدخل رمز التأكيد',
    defaultPassword: 'أدخل كلمة المرور الجديدة',
    confirmAccount: 'تأكيد حسابك',
    confirmationCode: 'رمز التأكيد',
    confirmAccountNow: 'تأكيد الحساب',
    resendCode: 'إعادة إرسال الرمز',
    changeSuccess: 'تمت إعادة تعيين كلمة المرور بنجاح!',
  }
};
