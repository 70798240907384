import React, { useContext, useEffect, useState } from 'react';
import Tooltip from './Tooltip'; // Assuming Tooltip is a separate component
import '../css/ResultsTable.css'; // Create and import a CSS file for custom styles
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext'; // Import LanguageContext

const ResultsTable = ({ results }) => {
  const { language } = useContext(LanguageContext); // Get the language from context

  // State variables for translated text
  const [functionNameText, setFunctionNameText] = useState(I18n.t('results.functionName'));
  const [timeComplexityText, setTimeComplexityText] = useState(I18n.t('results.timeComplexity'));
  const [testText, setTestText] = useState(I18n.t('results.test'));
  const [errorText, setErrorText] = useState(I18n.t('results.error'));
  const [failText, setFailText] = useState(I18n.t('results.fail'));
  const [passText, setPassText] = useState(I18n.t('results.pass'));
  const [noTestText, setNoTestText] = useState(I18n.t('results.noTest'));
  const [noLimitText, setNoLimitText] = useState(I18n.t('results.noLimit'));
  const [expectedText, setExpectedText] = useState(I18n.t('results.expected'));
  const [actualText, setActualText] = useState(I18n.t('results.actual'));
  const [failedRecursionText, setFailedRecursionText] = useState(I18n.t('results.failedRecursion'));
  const [failedLoopsText, setFailedLoopsText] = useState(I18n.t('results.failedLoops'));
  const [failedStructureText, setFailedStructureText] = useState(I18n.t('results.failedStructure'));

  // Update translations when the language changes
  useEffect(() => {
    setFunctionNameText(I18n.t('results.functionName'));
    setTimeComplexityText(I18n.t('results.timeComplexity'));
    setTestText(I18n.t('results.test'));
    setErrorText(I18n.t('results.error'));
    setFailText(I18n.t('results.fail'));
    setPassText(I18n.t('results.pass'));
    setNoTestText(I18n.t('results.noTest'));
    setNoLimitText(I18n.t('results.noLimit'));
    setExpectedText(I18n.t('results.expected'));
    setActualText(I18n.t('results.actual'));
    setFailedRecursionText(I18n.t('results.failedRecursion'));
    setFailedLoopsText(I18n.t('results.failedLoops'));
    setFailedStructureText(I18n.t('results.failedStructure'));
  }, [language]);

  if (!results || Object.keys(results).length === 0) return null;

  // Determine the maximum number of tests any function has
  function get_actual(actual, status) {
    if (status === -2) {
      console.log('actual:', actual);
      let jsonString = actual
        .replace(/'/g, '"')          // Replace single quotes with double quotes
        .replace(/\s+/g, ' ')        // Replace multiple spaces/newlines with single space
        .replace(/\\r\\n/g, '')      // Remove literal \r\n string
        .replace(/\r\n|\r|\n/g, '')  // Remove actual line breaks
        .replace(/True/g, 'true')    // Replace Python True
        .replace(/False/g, 'false')  // Replace Python False
        .replace(/,\s*}/g, '}')      // Remove trailing commas
        .trim();                     // Remove leading/trailing whitespace

      jsonString = jsonString.replace(/,\s*}/g, '}');
      console.log('jsonString:', jsonString);
      actual = JSON.parse(jsonString);
      if (actual.rec_needed && !actual.rec_cond) {
        return failedRecursionText;
      } else if (actual.n_loops_allowed > -1 && !actual.loops_cond) {
        return failedLoopsText.replace('{n_loops_allowed}', actual.n_loops_allowed).replace('{actual_loops}', actual.actual_loops);
      } else {
        return failedStructureText;
      }
    } else {
      return JSON.stringify(actual).slice(1, -1);
    }
  }

  const maxTests = Math.max(...Object.values(results).map(funcTests => funcTests.length));

  return (
    <div className="table-container">
      <table>
        <thead className="table-header">
          <tr>
            <th>{functionNameText}</th>
            <th>{timeComplexityText}</th>
            {[...Array(maxTests).keys()].map(i => (
              <th key={i}>{testText} {i + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(results).map((functionName, index) => (
            <tr key={functionName} className="table-row">
              <td>{functionName}</td>

              {/* Time Complexity Column */}
              {results[functionName].some(test => test.test_name === "time_complexity") ? (
                results[functionName].filter(test => test.test_name === "time_complexity").map((test, i) => (
                  <td 
                    key={i}
                    className={test.status < 0 ? 'status-error'
                      : test.status === 0 ? 'status-fail'
                      : 'status-pass'}
                  >
                    <Tooltip content={`${expectedText} ${test.expected}\n${actualText} ${JSON.stringify(test.actual).slice(1, -1)}`}>
                      {test.status < 0 ? errorText
                        : test.status === 0 ? failText
                        : passText}
                    </Tooltip>
                  </td>
                ))
              ) : (
                <td className="status-no-test">
                  {noLimitText}
                </td>
              )}

              {/* Test Columns */}
              {results[functionName].filter(test => test.test_name !== "time_complexity").map((test, i) => (
                <td 
                  key={i} 
                  className={test.status < 0 ? 'status-error'
                    : test.status === 0 ? 'status-fail'
                    : 'status-pass'}
                >
                  <Tooltip content={`${I18n.t('results.input')} ${test.stringified_input}\n${expectedText} ${JSON.stringify(test.expected).slice(1, -1)}\n${actualText} ${get_actual(test.actual, test.status)}`}>
                    {test.status < 0 ? errorText
                      : test.status === 0 ? failText
                      : passText}
                  </Tooltip>
                </td>
              ))}

              {/* Fill remaining cells with "No Test" */}
              {[...Array(maxTests - results[functionName].filter(test => test.test_name !== "time_complexity").length).keys()].map(i => (
                <td key={i + results[functionName].length} className="status-no-test">
                  {noTestText}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsTable;
