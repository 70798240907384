import React from 'react';
import I18n from '../../i18n';
import TableActions from './TableActions';

const BaseTable = ({
  items,
  headers,
  editingId,
  onEdit,
  onDelete,
  onDuplicate,
  onSave,
  onCancel,
  renderRow,
  selectedId,
  onRowClick,
  newItem,
  newRowRef
}) => {
  return (
    <table className="admin-table">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th 
              key={index}
              className={header.className}
              title={header.tooltip}
            >
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <tr
            key={item.id}
            onClick={() => onRowClick && onRowClick(item.id)}
            className={selectedId === item.id ? 'selected' : ''}
          >
            {renderRow(item, editingId === item.id, {
              onEdit,
              onDelete,
              onDuplicate,
              onSave,
              onCancel
            })}
          </tr>
        ))}
        {/* Show duplicate in edit mode */}
        {editingId?.toString().startsWith('dup_') && (
          <tr>
            {renderRow(
              { id: editingId },
              true,
              {
                onSave,
                onCancel
              }
            )}
          </tr>
        )}
        {/* Show new item in edit mode */}
        {newItem && (
          <tr ref={newRowRef}>
            {renderRow(newItem, true, {
              onSave,
              onCancel
            })}
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default BaseTable; 