import React from 'react';
import '../../css/AdminPage.css';

const StatusMessage = ({ message, type = 'success', onClose }) => {
  if (!message) return null;

  return (
    <div className={`status-message ${type}`}>
      {message}
      {onClose && (
        <button className="close-button" onClick={onClose}>
          ×
        </button>
      )}
    </div>
  );
};

export default StatusMessage; 