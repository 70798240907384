import React from 'react';
import TableActions from './TableActions';
import I18n from '../../i18n';

const validatePythonDict = (str) => {
  if (!str || str.trim() === '') return true;
  if (str.trim() === '{}') return true;
  const pythonDictRegex = /^\{(\s*['"][^'"]+['"]\s*:\s*[^,}]*\s*,?\s*)*\}$/;
  return pythonDictRegex.test(str);
};

const validateExpectedOutput = (value) => {
  if (!value || value.trim() === '') return true;
  if (!isNaN(value)) return true;
  if (['True', 'False', 'None'].includes(value)) return true;
  if (value.trim().startsWith('{') || 
      value.trim().startsWith('[') || 
      value.trim().startsWith('(')) return true;
  if ((value.startsWith("'") && value.endsWith("'")) || 
      (value.startsWith('"') && value.endsWith('"'))) return true;
  return false;
};

const TestRow = ({
  test,
  isEditing,
  editingData,
  setEditingData,
  actions
}) => {
  const handleChange = (field, value) => {
    setEditingData({
      ...editingData,
      [field]: value
    });
  };

  if (isEditing) {
    return (
      <>
        <TableActions
          item={{
            ...editingData,
            name: editingData?.test_name
          }}
          isEditing={true}
          onSave={actions.onSave}
          onCancel={actions.onCancel}
        />
        <td>{test.id}</td>
        <td>
          <input 
            type="text"
            value={editingData?.test_name || ''}
            onChange={(e) => handleChange('test_name', e.target.value)}
          />
        </td>
        <td>
          <textarea
            value={editingData?.input || ''}
            onChange={(e) => handleChange('input', e.target.value)}
          />
        </td>
        <td>
          <textarea
            value={editingData?.expected || ''}
            onChange={(e) => handleChange('expected', e.target.value)}
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={editingData?.allow_each_expected === 1}
            onChange={(e) => handleChange('allow_each_expected', e.target.checked ? 1 : 0)}
          />
        </td>
        <td>
          <textarea
            value={editingData?.postprocess || ''}
            onChange={(e) => handleChange('postprocess', e.target.value)}
          />
        </td>
        <td>
          <textarea
            value={editingData?.init_input || ''}
            onChange={(e) => handleChange('init_input', e.target.value)}
          />
        </td>
      </>
    );
  }

  return (
    <>
      <TableActions
        item={{
          ...test,
          name: test.test_name
        }}
        isEditing={false}
        onEdit={actions.onEdit}
        onDelete={actions.onDelete}
        onDuplicate={actions.onDuplicate}
      />
      <td>{test.id}</td>
      <td>{test.test_name}</td>
      <td>{test.input}</td>
      <td>{test.expected}</td>
      <td>{test.allow_each_expected ? 'Yes' : 'No'}</td>
      <td>{test.postprocess}</td>
      <td>{test.init_input}</td>
    </>
  );
};

export default TestRow; 