import React from 'react';
import '../../css/AdminPage.css';

const LoadingSpinner = ({ overlay }) => {
  if (overlay) {
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return <div className="loading-spinner"></div>;
};

export default LoadingSpinner; 