import React from 'react';
import BaseTable from './BaseTable';
import TestableRow from './TestableRow';
import I18n from '../../i18n';

const DisplayTestableTable = ({
  testables,
  editingTestableId,
  editingData,
  editingDataMap,
  setEditingDataMap,
  setEditingData,
  duplicatedRows = [],
  newTestable,
  newRowRef,
  selectedTestableId,
  handleTestableEdit,
  handleTestableDelete,
  handleTestableSave,
  handleTestableDuplicate,
  handleTestableCancel,
  handleTestableRowClick
}) => {
  const headers = [
    { 
      title: I18n.t('admin.headers.actions'),
      className: 'col-actions',
      tooltip: I18n.t('admin.headers.tooltips.actions')
    },
    { 
      title: I18n.t('admin.headers.testableId'),
      className: 'col-id',
      tooltip: I18n.t('admin.headers.tooltips.testableId')
    },
    { 
      title: I18n.t('admin.headers.testableName'),
      className: 'col-name',
      tooltip: I18n.t('admin.headers.tooltips.testableName')
    },
    { 
      title: I18n.t('admin.headers.type'),
      className: 'col-type',
      tooltip: I18n.t('admin.headers.tooltips.type')
    },
    { 
      title: I18n.t('admin.headers.className'),
      className: 'col-class-name',
      tooltip: I18n.t('admin.headers.tooltips.className')
    },
    { 
      title: I18n.t('admin.headers.initInput'),
      className: 'col-init-input',
      tooltip: I18n.t('admin.headers.tooltips.initInput')
    },
    { 
      title: I18n.t('admin.headers.timeComplexity'),
      className: 'col-time-complexity',
      tooltip: I18n.t('admin.headers.tooltips.timeComplexity')
    },
    { 
      title: I18n.t('admin.headers.recursive'),
      className: 'col-checkbox',
      tooltip: I18n.t('admin.headers.tooltips.recursive')
    },
    { 
      title: I18n.t('admin.headers.maxLoops'),
      className: 'col-max-loops',
      tooltip: I18n.t('admin.headers.tooltips.maxLoops')
    }
  ];

  const renderRow = (testable, isEditing, actions) => {
    if (!testable) return null;

    const isDuplicate = testable.id?.toString().startsWith('dup_');
    const currentEditData = isDuplicate ? editingDataMap[testable.id] : editingData;

    const editData = {
      ...testable,
      name: (testable === newTestable ? '' : testable.name) || '',
      type: (testable === newTestable ? '' : testable.type) || '',
      class_name: (testable === newTestable ? '' : testable.class_name) || '',
      init_input: (testable === newTestable ? '' : testable.init_input) || ''
    };

    const enhancedActions = {
      ...actions,
      onSave: isEditing ? actions.onSave : undefined,
      onCancel: isEditing ? actions.onCancel : undefined,
      onEdit: !isEditing ? actions.onEdit : undefined,
      onDelete: !isEditing ? actions.onDelete : undefined,
      onDuplicate: !isEditing ? actions.onDuplicate : undefined
    };

    return (
      <TestableRow
        testable={testable}
        isEditing={isEditing || testable === newTestable}
        editingData={currentEditData || editData}
        setEditingData={isDuplicate 
          ? (data) => setEditingDataMap(prev => ({...prev, [testable.id]: data}))
          : setEditingData
        }
        actions={enhancedActions}
      />
    );
  };

  return (
    <BaseTable
      items={testables.filter(t => t !== newTestable || editingData)}
      headers={headers}
      editingId={editingTestableId}
      onEdit={handleTestableEdit}
      onDelete={handleTestableDelete}
      onDuplicate={handleTestableDuplicate}
      onSave={handleTestableSave}
      onCancel={handleTestableCancel}
      renderRow={renderRow}
      selectedId={selectedTestableId}
      onRowClick={handleTestableRowClick}
      newItem={newTestable}
      newRowRef={newRowRef}
    />
  );
};

export default DisplayTestableTable; 