import React from 'react';
import TableActions from './TableActions';
import I18n from '../../i18n';

const TestableRow = ({
  testable,
  isEditing,
  editingData,
  setEditingData,
  actions
}) => {
  const timeComplexityOptions = [
    '', 'O(1)', 'O(logn)', 'O(n)', 'O(nlogn)', 'O(n^2)', 
    'O(n^3)', 'O(n^4)', 'O(2^n)'
  ];

  const typeOptions = ['function', 'method', 'variable'];

  const handleChange = (field, value) => {
    setEditingData({
      ...editingData,
      [field]: value
    });
  };

  if (isEditing) {
    return (
      <>
        <TableActions
          item={editingData}
          isEditing={true}
          onSave={actions.onSave}
          onCancel={actions.onCancel}
        />
        <td>{testable.id}</td>
        <td>
          <input
            type="text"
            value={editingData?.name || ''}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </td>
        <td>
          <select
            value={editingData?.type || ''}
            onChange={(e) => handleChange('type', e.target.value)}
          >
            <option value="">Select type</option>
            {typeOptions.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </td>
        <td>
          <input
            type="text"
            value={editingData?.class_name || ''}
            onChange={(e) => handleChange('class_name', e.target.value)}
          />
        </td>
        <td>
          <textarea
            value={editingData?.init_input || ''}
            onChange={(e) => handleChange('init_input', e.target.value)}
          />
        </td>
        <td>
          <select
            value={editingData?.time_complexity || ''}
            onChange={(e) => handleChange('time_complexity', e.target.value)}
          >
            <option value="">Select complexity</option>
            {timeComplexityOptions.filter(option => option !== '').map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </td>
        <td>
          <input
            type="checkbox"
            checked={editingData?.is_recursive === 1}
            onChange={(e) => handleChange('is_recursive', e.target.checked ? 1 : 0)}
          />
        </td>
        <td>
          <input
            type="number"
            value={editingData?.max_loop_count || 0}
            onChange={(e) => handleChange('max_loop_count', parseInt(e.target.value))}
          />
        </td>
      </>
    );
  }

  return (
    <>
      <TableActions
        item={testable}
        isEditing={false}
        onEdit={actions.onEdit}
        onDelete={actions.onDelete}
        onDuplicate={actions.onDuplicate}
      />
      <td>{testable.id}</td>
      <td>{testable.name}</td>
      <td>{testable.type}</td>
      <td>{testable.class_name}</td>
      <td>{testable.init_input}</td>
      <td>{testable.time_complexity}</td>
      <td>{testable.is_recursive ? 'Yes' : 'No'}</td>
      <td>{testable.max_loop_count}</td>
    </>
  );
};

export default TestableRow; 