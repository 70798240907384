import React from 'react';
import TableActions from './TableActions';
import I18n from '../../i18n';

const QuestionRow = ({
  question,
  isEditing,
  editingData,
  setEditingData,
  actions
}) => {
  const handleChange = (field, value) => {
    const newData = {
      ...editingData,
      [field]: value
    };
    setEditingData(newData);
  };

  if (isEditing) {
    return (
      <>
        <TableActions
          item={editingData}  // Pass editingData instead of question
          isEditing={true}
          onSave={actions.onSave}
          onCancel={actions.onCancel}
        />
        <td>{question.id}</td>
        <td>
          <input 
            type="text"
            value={editingData?.name || ''}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </td>
        <td>
          <textarea 
            value={editingData?.text || ''}
            onChange={(e) => handleChange('text', e.target.value)}
          />
        </td>
        <td>
          <input 
            type="number"
            min="1" 
            value={editingData?.difficulty || 1}
            onChange={(e) => handleChange('difficulty', parseInt(e.target.value))}
          />
        </td>
      </>
    );
  }

  return (
    <>
      <TableActions
        item={question}
        onEdit={actions.onEdit}
        onDuplicate={actions.onDuplicate}
        onDelete={actions.onDelete}
      />
      <td>{question.id}</td>
      <td>{question.name}</td>
      <td>{question.text}</td>
      <td>{question.difficulty}</td>
    </>
  );
};

export default QuestionRow; 