import React from 'react';
import BaseTable from './BaseTable';
import TestRow from './TestRow';
import I18n from '../../i18n';

const DisplayTestTable = ({
  tests,
  editingTestId,
  editingData,
  editingDataMap,
  setEditingDataMap,
  setEditingData,
  duplicatedRows = [],
  newTest,
  newRowRef,
  handleTestEdit,
  handleTestDelete,
  handleTestSave,
  handleTestDuplicate,
  handleTestCancel
}) => {
  const headers = [
    { 
      title: I18n.t('admin.headers.actions'),
      className: 'col-actions',
      tooltip: I18n.t('admin.headers.tooltips.actions')
    },
    { 
      title: I18n.t('admin.headers.testId'),
      className: 'col-id',
      tooltip: I18n.t('admin.headers.tooltips.testId')
    },
    { 
      title: I18n.t('admin.headers.testName'),
      className: 'col-name',
      tooltip: I18n.t('admin.headers.tooltips.testName')
    },
    { 
      title: I18n.t('admin.headers.input'),
      className: 'col-input',
      tooltip: I18n.t('admin.headers.tooltips.input')
    },
    { 
      title: I18n.t('admin.headers.expected'),
      className: 'col-expected',
      tooltip: I18n.t('admin.headers.tooltips.expected')
    },
    { 
      title: I18n.t('admin.headers.allowEachExpected'),
      className: 'col-allow-each narrow-col',
      tooltip: I18n.t('admin.headers.tooltips.allowEachExpected')
    },
    { 
      title: I18n.t('admin.headers.postprocess'),
      className: 'col-postprocess wide-col',
      tooltip: I18n.t('admin.headers.tooltips.postprocess')
    },
    { 
      title: I18n.t('admin.headers.initInput'),
      className: 'col-init-input wide-col',
      tooltip: I18n.t('admin.headers.tooltips.initInput')
    }
  ];

  const renderRow = (test, isEditing, actions) => {
    if (!test) return null;

    const isDuplicate = test.id?.toString().startsWith('dup_');
    const currentEditData = isDuplicate ? editingDataMap[test.id] : editingData;

    // Initialize editData with all fields from the test
    const editData = {
      ...test,
      test_name: (test === newTest ? '' : test.test_name) || '',
      input: (test === newTest ? '' : test.input) || '',
      expected: (test === newTest ? '' : test.expected) || ''
    };

    return (
      <TestRow
        test={test}
        isEditing={isEditing || test === newTest}
        editingData={currentEditData || editData}
        setEditingData={isDuplicate 
          ? (data) => setEditingDataMap(prev => ({...prev, [test.id]: data}))
          : setEditingData
        }
        actions={actions}
      />
    );
  };

  return (
    <div className="table-container">
      <BaseTable
        items={tests}
        headers={headers}
        editingId={editingTestId}
        onEdit={handleTestEdit}
        onDelete={handleTestDelete}
        onDuplicate={handleTestDuplicate}
        onSave={handleTestSave}
        onCancel={handleTestCancel}
        renderRow={renderRow}
        newItem={newTest}
        newRowRef={newRowRef}
      />
    </div>
  );
};

export default DisplayTestTable; 